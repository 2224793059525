<template>
  <div style="position: relative;">
    <div class="right_btns">
      <el-button @click="daochu()" type="primary" size="small" icon="FolderOpened" :disabled="!$buttonStatus('jx_wrz_dc')">
        导出
      </el-button>
      <qzf-button button_code="jx_wrz_plrz" class="filter-item1" type="primary" size="small" @click="approve" v-if="listQuery.status == 1"   >
        <el-icon><Pointer /></el-icon><span  >认证</span>
      </qzf-button>
      <qzf-button button_code="jx_wrz_plqxrz" class="filter-item1" type="primary" size="small" @click="unApprove" v-if="listQuery.status == 2"   >
        <el-icon><Pointer /></el-icon><span  >取消认证</span>
      </qzf-button>
      <!-- <settings name="设置" :type="['code_wrzjxs']" v-if="$buttonStatus('fp_wrzjxlb_sz')"></settings> -->
    </div>
    <el-tabs v-model="listQuery.status" @tab-click="handleClick">
      <el-tab-pane label="未认证" :name="1"></el-tab-pane>
      <el-tab-pane label="本月认证" :name="2"></el-tab-pane>
    </el-tabs>
    <div style="margin-bottom:10px">
      <el-input size="small" v-model="listQuery.fphm" style="width:120px" placeholder="发票号码" clearable @keyup.enter.native="getList"></el-input>
      <el-button style="margin-right: 5px;" size="small" @click="getList" type="primary" icon="Search">搜索</el-button>
      <el-select
        v-model="listQuery.bjrzzt"
        placeholder="请选择"
        size="small"
        style="width: 120px"
        @change="getList"
      >
        <el-option label="全部" :value="0" />
        <el-option label="可认证" :value="1" />
        <el-option label="不可认证" :value="2" />
      </el-select>
    </div>
    <el-table stripe :data="list" style="width: 100%" :height="contentStyleObj" @select="handleSelectionChange" border v-loading="loading" ref="table" id="tableLazyLoad" @select-all="handleSelectionChangeAll">
      <template #empty>
        <el-empty :image-size="150" description="没有数据"></el-empty>
      </template>
      <el-table-column type="selection" width="55" align="center"/>
      <el-table-column align="center" prop="period" label="账期" width="80" />
      <el-table-column align="center" prop="invoice_date" label="开票日期" width="100">
        <template #default="scope">
          <span>{{ $parseTime(scope.row.invoice_date, "{y}-{m}-{d}")}}</span>
        </template>
      </el-table-column>
      <el-table-column align="center" prop="fpdm" label="发票代码" width="180" />
      <el-table-column align="center" prop="fphm" label="发票号码" />
      <el-table-column align="center" prop="corpName" label="公司名称" />
      <el-table-column align="center" prop="invoiceType" label="发票类型">
        <template #default="scope">
          <span v-if="scope.row.invoiceType == '1'">专票</span>
          <span v-else-if="scope.row.invoiceType == '2'">普票</span>
          <span v-else-if="scope.row.invoiceType == '3'">农产品发票</span>
          <span v-else-if="scope.row.invoiceType == '4'">电子专票</span>
          <span v-else-if="scope.row.invoiceType == '5'">电子普票</span>
          <span v-else-if="scope.row.invoiceType == '6'">海关缴款书</span>
          <span v-else-if="scope.row.invoiceType == '7'">其他发票</span>
        </template>
      </el-table-column>
      <el-table-column align="center" v-if="listQuery.status == 2" prop="periodZz" label="认证账期" />
      <el-table-column align="center" v-if="listQuery.status == 2" prop="voucherNo" label="凭证号" />
      <el-table-column align="center" prop="sumAmount" label="金额" />
      <el-table-column align="center" prop="sumTax" label="税额" />
      <el-table-column align="center" prop="sumTotal" label="价税合计" />
      <el-table-column align="center" prop="bjrzzt" label="标识">
        <template #default="scope">
          <span style="color:green" v-if="scope.row.bjrzzt == 1">可认证</span>
          <span v-else></span>
        </template>
      </el-table-column>
    </el-table>
    <div class="pagination">
      <qzf-pagination v-show="total>0" :total="total" v-model:page="listQuery.page" v-model:limit="listQuery.limit" @pagination="getList" />
    </div>
  </div>
</template>

<script>
import { inInvoiceWzzList,uncertifiedSave,uncertifiedSaveNo } from "@/api/invoice"
import { exportInInvoiceWzz } from "../../../api/export"
export default {
  name:'incomeWzz',
  data() {
    return {
      list: [],
      total: 0,
      listQuery:{
        page: 1,
        limit: 20,
        status:1,
      },
      gsMainIds: [],
      period:this.$store.getters['user/comInfo'].period,
      selects:[],
      loading:false,
      totalPage:0,
      pageSize:50,
      tableData:[],
      currentPage:1,
      allChecked:false
    }
  },
  created() {
    this.contentStyleObj = this.$getHeight(299)
    //this.getList()
    //console.log(this.period);
  },
  methods:{
    getList() {
      this.loading = true
      inInvoiceWzzList(this.listQuery).then(res=>{
        this.loading = false
        this.tableData = res.data.data.list ? res.data.data.list : []
        this.total = res.data.data.total
        this.setScroll()
      })
    },
    setScroll(){
      this.selects = []
      this.gsMainIds = []
      this.allChecked = false
      this.totalPage = this.tableData.length/this.pageSize
      if(this.tableData.length % this.pageSize == 0){
        this.totalPage = this.tableData.length/this.pageSize
      }else{
        this.totalPage = parseInt(this.tableData.length/this.pageSize) + 1
      }
      this.list = this.tableData.slice(0,this.currentPage*this.pageSize)
      this.lazyLoading()
    },
    lazyLoading(){
      let lazy = document.getElementById("tableLazyLoad");
      let dom = lazy.querySelector(".el-scrollbar__wrap");
      let that = this
      dom.addEventListener("scroll", function () {
        const scrollDistance = dom.scrollHeight - dom.scrollTop - dom.clientHeight;
        if (scrollDistance <= 5) {
          if (that.currentPage < that.totalPage) {
            that.currentPage++;
            that.list = that.tableData.slice(
              0,
              that.currentPage * that.pageSize
            );
            if(that.selects.length == that.tableData.length && that.allChecked){
              that.$refs.table.toggleAllSelection()
              that.allChecked = true
            }else if(that.selects.length != 0){
              that.selects.map(v=>{
                that.list.map(item=>{
                  if(item.id == v.id){
                    that.$nextTick(()=>{
                     that.$refs.table.toggleRowSelection(item,true)
                    })
                  }
                })
              })
            }
          }
        }
      });
    },
    getIds(){
      this.gsMainIds = []
      this.selects.map(info=>{
        this.gsMainIds.push({id:info.id})
      })
    },
    handleSelectionChange(val) {
      if(this.allChecked && this.list.length != this.tableData.length){
        let currentIndex = 0
        this.list.map((v,i2)=>{
          val.map(e=>{
            if(e.id == v.id){
              currentIndex = i2
            }
          })
        })
        let arr = this.tableData.slice(currentIndex+1,this.tableData.length)
        let newArr = [...val,...arr]
        this.selects = newArr
      }else{
        this.selects = val;
      }
      this.getIds()
    },
    handleSelectionChangeAll(e){
      if(e.length != 0){
        this.selects = this.tableData
        this.getIds()
        this.allChecked = true
      }else{
        this.selects = []
        this.gsMainIds = []
        this.allChecked = false
      }
    },
    approve() {
      let rzStatus = false
      this.selects.map(v => {
        if(v.period == this.period){
          rzStatus = true
        }
      })
      if(rzStatus){
        this.$qzfMessage('当月不可认证当月发票！',1)
        return
      }
      this.$confirm('确定要认证吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        uncertifiedSave(this.gsMainIds).then(res => {
          if(res.data.msg == 'success') {
            this.$qzfMessage('已认证！',3)
            this.getList()
          }
        })
      })
    },
    unApprove() {
      this.$confirm('确定取消认证吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        uncertifiedSaveNo(this.gsMainIds).then(res => {
          if(res.data.msg == 'success') {
            this.$qzfMessage('已取消认证！',3)
            this.getList()
          }
        })
      })
    },
    handleClick(tab) {
      if(tab.props.name == 1) {
        this.listQuery.status = 1
        this.getList()
      }if(tab.props.name == 2) {
        this.listQuery.status = 2
        this.getList()
      }
    },
    daochu(){
      exportInInvoiceWzz(this.listQuery).then(res=>{
        if(res.data.msg == 'success'){
          window.open(res.data.data.url);
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.right_btns{
  position: absolute;
  right: 5px;
  z-index: 99;
}
</style>